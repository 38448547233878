/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import { Link } from 'react-router-dom'

const DashboardPage = (props) => (
  
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className="row gy-5 g-xl-10">
                
                <div className="col-xl-6">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/patients" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Patients</Link>
                                
                                <div className="mt-5">
                                    <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalPatient}</span>
                                    {/* <span className="text-dark fw-bolder fs-6 lh-0">+ 28 this week</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-6">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/tests" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Tests</Link>
                                
                                    <div className="mt-5">
                                        <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalTests}</span>
                                    
                                    </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-6">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/categories" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Categories</Link>
                                
                                    <div className="mt-5">
                                        <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalCategories}</span>
                                        {/* <span className="text-dark fw-bolder fs-6 lh-0">+ 28 this week</span> */}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
)


const DashboardWrapper = () => {
    const intl = useIntl()
    const [stats, setStats] = useState({})

    useEffect(() => {
        const token =  localStorage.getItem('kt-auth-react-v.api_token')
        axios.get(`${process.env.REACT_APP_API_URL}/adminuser/dashboard-stats`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                setStats(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage stats={stats} />
        </>
    )
}

export {DashboardWrapper}
