import React,{useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {Table} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import ReactPaginate from 'react-paginate';

const Categories = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token') //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2FhOTkyYTM3NmI4Y2UxOWYwMzE5NmQiLCJpYXQiOjE2NzIxMjQ3NDl9.GVST69iPilsjJgfTp9os8Gr-9KyKEf4c5LvbleQss74'
    
    const [modalTitle, setModalTitle] = useState('Add Category');
    const [lgshow, setShow] = useState(false);

    const editorRef = useRef(null);
    const [editorText, setEditorText] = useState('');
    const [editorInitial, setEditorInitial] = useState("");

    const [price, setPrice] = useState(0);
    const [old_price, setOldPrice] = useState(0);
    const [category_id, setCategoryId] = useState(0);
    const [category_name, setCategoryName] = useState("");
    const [parent_category, setParentCategory] = useState("");
    const [category_status, setCategoryStatus] = useState(1);
    const [short_description, setShortDescription] = useState("");
    const [category_photo, setCategoryPhoto] = useState();
    const [category_photo_selected, setCategoryPhotoSelected] = useState("");
    const [more_test_check, setMoreTestCheck] = useState(false);
    const [more_test_check_option, setMoreTestCheckOption] = useState(false);
    const [more_test_text, setMoreTestText] = useState("Other Tests: Kindly contact us for additional tests and pricing.");
    const [count, setCount] = useState(0);
    const [validated, setValidated] = useState(false);
    const [fileSizeErrorPhoto , setFileSizeErrorPhoto] = useState(false);
    const [fileFormatError, setFileFormatError] = useState(false);
    const [isEditPhoto, setIsEditPhoto] = useState(false);
    const [isPopular, setIsPopular] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)

    const [offset, setOffset] = useState(0);
    const [sortBy, setSortBy] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [contentLoading, setContentLoading] = useState(false);

    const [allCategories, setAllCategories] = useState([]);

    const [categories, setCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [orderNumber, setOrderNumber] = useState(1);

    const [dataPerPage, setDataPerPage] = useState(20);
    const [viewMode, setViewMode] = useState(false);

    useEffect(() => {
        getCategories();
    }, [offset, dataPerPage, sortBy, searchQuery])

    const getCategoriesData = (data, offset) => {
        return (
            data.map(category =>{
                if(category.status === 1){
                    var category_status_name = 'Active'
                    var status = 'success'
                }else{
                    var category_status_name = 'Inactive'  
                    var status = 'danger' 
                }
                return (
                    <tr id={`row`+category._id}>
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                            </div>
                        </td>
                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                    {category.category_name}
                                </div>
                            </div>
                        </td>
                        <td>
                            {category.child_of}
                        </td>
                        <td>
                        <span className='fw-bold text-muted'>$</span> {category.price} 
                        </td>
                        <td className=''>
                            <span className={`badge badge-light-`+status}>{category_status_name}</span>
                        </td>
                        <td className=''>
                            <div className='d-flex flex-shrink-0'>
                                <button onClick={() => viewDetails(category._id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen028.svg'
                                    className='svg-icon-3'
                                />
                                </button>
                                <button onClick={() => editCategory(category._id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </button>
                                <button onClick={() => deleteCategory(category._id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                />
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            })
        )
    }

    const getCategories = async () => {
        setContentLoading(true);
        
        if (searchQuery === '' && sortBy === -1) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/categories?offset=${offset}&limit=${dataPerPage}`,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
            
            const data = res.data.data;
            // const slice = data.slice(offset - 1 , offset - 1 + parseInt(dataPerPage));
            // console.log(slice);
            const categoriesData = getCategoriesData(data);
            setCategoryNames(res.data.categoriesArray);
            setAllCategories(categoriesData);
            setPageCount(Math.ceil(res.data.total / dataPerPage));
            setTotalItems(res.data.total);
            setContentLoading(false);
            }).catch(err => {
                console.log(err);
            })
        }else{
            getCategorriesBySearch();
           
        }
        setContentLoading(false);
    }

    const getCategorriesBySearch = async () => {
        // setContentLoading(true);
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/categories?offset=${offset}&limit=${dataPerPage}&query=${searchQuery}&sort=${sortBy}`,{ headers: {"Authorization" : ` ${token}`}})
        .then(res => {
            const data = res.data.data;
            const categoriesData = getCategoriesData(data);
            setCategoryNames(res.data.categoriesArray);
            setAllCategories(categoriesData);
            setPageCount(Math.ceil(res.data.total / dataPerPage));
            setTotalItems(res.data.total);
            setContentLoading(false);
        }
        ).catch(err => {
            console.log(err);
        }
        )
    }


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        console.log(form.checkValidity());
        if(form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
            
        }
        setValidated(true);
        if(form.checkValidity() === true){
            e.preventDefault();
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${btn.innerHTML}`;
            btn.disabled = true;

            if(category_id !== 0){
                const data = {
                    category_id: category_id,
                    category_name: category_name,
                    price: price,
                    old_price: old_price,
                    parent_category: parent_category,
                    category_status: category_status,
                    short_description: short_description,
                    more_test_check: more_test_check,
                    more_test_text: more_test_text,
                    long_description: editorText,
                    order: orderNumber,
                }
                updateCategory(data);
            
            }else{
                const data = {
                    category_name: category_name,
                    parent_category: parent_category,
                    price: price,
                    old_price: old_price,
                    category_status: category_status,
                    short_description: short_description,
                    more_test_check: more_test_check,
                    more_test_text: more_test_text,
                    long_description: editorText,
                    order: orderNumber,
                }
                addCategory(data);
            }
        }
    }

    const addCategory = async (data) => {
        const formData = new FormData();
        formData.append('category_name', data.category_name);
        formData.append('child_of', data.parent_category);
        formData.append('status', data.category_status);
        formData.append('price', data.price);
        formData.append('old_price', data.old_price);
        formData.append('short_description', data.short_description);
        formData.append('long_description', data.long_description);
        formData.append('popular', isPopular);
        formData.append('photo', category_photo_selected);
        formData.append('order', data.order);
        formData.append('more_test_check', data.more_test_check);
        formData.append('more_test_text', data.more_test_text);

        axios.post(`${process.env.REACT_APP_API_URL}/categories`, formData,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                toast.success('Category added successfully');
                handleClose();
                getCategories();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        }
        )
        .finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = modalTitle;
            btn.disabled = false;
        })
    }
    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage +1);
        // console.log(newOffset);
        setOffset(newOffset);
    };
    const updateCategory = async (data) => {
        const fileName = category_photo.split('/').pop();
        const formData = new FormData();
        formData.append('category_name', data.category_name);
        formData.append('child_of', data.parent_category);
        formData.append('status', data.category_status);
        formData.append('price', data.price);
        formData.append('old_price', data.old_price);
        formData.append('short_description', data.short_description);
        formData.append('long_description', data.long_description);
        formData.append('popular', isPopular);
        formData.append('order', data.order);
        formData.append('more_test_check', data.more_test_check);
        formData.append('more_test_text', data.more_test_text);

        if(category_photo_selected.name != undefined && fileName != category_photo_selected.name){
            formData.append('photo', category_photo_selected);
        }

        axios.patch(`${process.env.REACT_APP_API_URL}/categories/${data.category_id}`, formData,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                toast.success('Category updated successfully');
                handleClose();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
    }
    
    const handlePhoto = (e) => {
        setFileSizeErrorPhoto(false);
        setFileFormatError(false);

        if(e.target.files[0].size > 300000){
            setFileSizeErrorPhoto(true);
            e.target.value = null;
            return;
        }else{
            if(e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/svg' && e.target.files[0].type !== 'image/svg+xml'  && e.target.files[0].type !== 'image/webp'){
                // toast.error('File format not supported');
                setFileFormatError(true);
                e.target.value = null;
                return;
            }else{
                setCategoryPhotoSelected(e.target.files[0]);
                setIsEditPhoto(false)
            }
        }
    }

    const handleShow = (e, title="Add Category", button="Add Category", viewMode=false) => {
            if(viewMode){
                setViewMode(true);
            }else{
                setViewMode(false);
            }
        
        // console.log(title, button);
            setModalTitle(title);
            setModalTitle(button);
            setShow(true);
    }

    const handleClose = () => {
        setValidated(false);
        setCategoryId(0);
        setCategoryName("");
        setParentCategory("");
        setCategoryStatus(1);
        setShortDescription("");
        setCategoryPhoto("");
        setEditorText("");
        setMoreTestCheck(false);
        setMoreTestText("Other Tests: Kindly contact us for additional tests and pricing.");
        setMoreTestCheckOption(false);
        setIsPopular(0);
        setPrice(0);
        setOldPrice(0);
        setOrderNumber(1);
        setEditorInitial("");
        setIsEditPhoto(false);
        setShow(false);

    }

    const viewDetails = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setCategoryId(res.data.data._id);
            setCategoryName(res.data.data.category_name);
            setParentCategory(res.data.data.child_of);
            setCategoryStatus(res.data.data.status);
            setShortDescription(res.data.data.short_description);
            setCategoryPhotoSelected(res.data.data.photo);
            setIsPopular(res.data.data.popular);
            setCategoryPhoto(res.data.data.photo);
            setPrice(res.data.data.price);
            setOrderNumber(res.data.data.order);
            setOldPrice(res.data.data.old_price);
            setMoreTestCheck(res.data.data.more_test_check);
            setMoreTestText(res.data.data.more_test_text);
            if(res.data.data.child_of == '0' || res.data.data.child_of == '000000000000000000000000'){
                setMoreTestCheckOption(true);
            } else{
                setMoreTestCheckOption(false);
            }
            setEditorText(res.data.data.long_description);
            setEditorInitial(res.data.data.long_description);
            setIsEditPhoto(true);

            handleShow("", 'View Details', 'View Details', true);
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const editCategory = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setCategoryId(res.data.data._id);
            setCategoryName(res.data.data.category_name);
            setParentCategory(res.data.data.child_of);
            setCategoryStatus(res.data.data.status);
            setShortDescription(res.data.data.short_description);
            setCategoryPhotoSelected(res.data.data.photo);
            setIsPopular(res.data.data.popular);
            setPrice(res.data.data.price);
            setOldPrice(res.data.data.old_price);
            setOrderNumber(res.data.data.order);
            setMoreTestCheck(res.data.data.more_test_check);
            if(res.data.data.more_test_text === ''){
                setMoreTestText("Other Tests: Kindly contact us for additional tests and pricing.");
            } else{
                setMoreTestText(res.data.data.more_test_text);
            }
            setMoreTestText(res.data.data.more_test_text);
            if(res.data.data.child_of == '0' || res.data.data.child_of == '000000000000000000000000'){
                setMoreTestCheckOption(true);
            } else{
                setMoreTestCheckOption(false);
            }
            setCategoryPhoto(res.data.data.photo);
            setEditorText(res.data.data.long_description);
            setEditorInitial(res.data.data.long_description);
            setIsEditPhoto(true);
            
            handleShow("", 'Edit Category', 'Update Category');
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const deleteCategory = async (id) => {
        // confirm
        const confirm = window.confirm('Are you sure you want to delete this category?');
        if(confirm){
            axios.delete(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization": ` ${token}`}})
            .then(res => {
                toast.success('Category deleted successfully');
                getCategories();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
        }
    }

    const changeCategoryStatus = (e) => {
        if(e.target.checked){
            setCategoryStatus(1)
        }else{
            setCategoryStatus(0)
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        // console.log();
        setSearchQuery(e.target[0].value);
    }

    const changeParentCategory = (e) => {
        setParentCategory(e.target.value);
        if(e.target.value === "000000000000000000000000" || e.target.value === "0"){
            setMoreTestCheck(true);
            setMoreTestCheckOption(true)
        }else{
            setMoreTestCheck(false);
            setMoreTestCheckOption(false)
        }
    }
        

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Categories</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Categories <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                   
                    <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    

                    <button
                        onClick={e=>handleShow(e, "Add Category", "Add Category")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Category
                    </button>
                    </div>
                    
                </div>
                <div className='card-header border-0 pt-2'>
                    <div className='card-toolbar'>
                        <div className='input-group input-group-sm input-group-solid w-230px border-zk'>
                            <span className='input-group-text'>
                                <KTSVG
                                path='/media/icons/duotune/general/gen004.svg'
                                className='svg-icon-2 svg-icon-gray-900'
                                />
                            </span> 
                            <form className='d-flex align-items-center' onSubmit={handleSearch}>
                                <input
                                type='text'
                                className='form-control ps-2 '
                                placeholder='Search'
                                // value={searchQuery}
                                // onChange={e=>setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Category Name</th>
                                    <th className='min-w-140px'>Parent Category</th>
                                    <th className='min-w-140px'>Price</th>
                                    <th className='min-w-50px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contentLoading ? <tr className=" w-100 text-center">
                                        <td colSpan={5} >
                                            <span className='text-center'>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>

                                            </span>
                                        </td></tr> :
                                        <>
                                    {allCategories}
                                </>
                                }
                                
                            </tbody>
                        </table>
                        <div className='text-center pb-4'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>


            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
                <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Form.Group controlId="CategoryName">
                                <Form.Label>Category Name <span className='text-danger'>*</span></Form.Label>
                                <Form.Control className='form-control-sm' type="test" placeholder="Enter Category Name" value={category_name} onChange={(e)=> setCategoryName(e.target.value)} required disabled={viewMode}/>
                            </Form.Group>
                            {/* options for parent category */}
                            <Form.Group controlId="ParentCategory" className='mt-5'>
                                <Form.Label>Parent Category</Form.Label>
                                <Form.Select aria-label="Default select example" className='form-select-sm' value={parent_category} onChange={e=>changeParentCategory(e)} disabled={viewMode}>
                                    <option value='0'>Select Parent Category </option>
                                    <option value='000000000000000000000000'>Main Category</option>
                                    {categoryNames.map((category, index) => {
                                        return (
                                            <option key={index} value={category._id}>{category.category_name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                            {/* photo */}
                            
                            

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control className='form-control-sm' type="number" placeholder="Enter Price" value={price} onChange={(e)=> setPrice(e.target.value)} disabled={viewMode} min="0"/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>Old Price</Form.Label>
                                        <Form.Control className='form-control-sm' type="number" placeholder="Enter Old Price" value={old_price} onChange={(e)=> setOldPrice(e.target.value)} disabled={viewMode} min="0"/>
                                    </Form.Group>
                                </div>
                            </div>  
                           
                            <div className='row'>
                                <div className='col-6'>
                                    
                                    <Form.Group controlId="formBasicStatus" className='mt-5'>
                                        <Form.Label>Status</Form.Label>

                                       
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={category_status} onChange={(e)=> setCategoryStatus(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Active</option>
                                            <option value='0'>Inactive</option>
                                        </Form.Select>
                                        
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPopular" className='mt-5'>
                                        <Form.Label>Popular</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={isPopular} onChange={(e)=> setIsPopular(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>

                            <Form.Group controlId="formBasicPopular2" className='mt-5'>
                                <Form.Label>Test Order</Form.Label>
                                <Form.Control className='form-control-sm' min="1" type="number" value={orderNumber} onChange={(e)=> setOrderNumber(e.target.value)} disabled={viewMode}>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formBasicShortDescription" className='mt-5'>
                                <Form.Label>Short Description <span className='text-danger'>*</span></Form.Label>
                                <Form.Control as="textarea" rows={3} value={short_description} onChange={(e)=> setShortDescription(e.target.value)} required disabled={viewMode}/>
                            </Form.Group>
                                {more_test_check_option? (
                                 <Form.Group controlId="formBasicMoreTest" className='mt-5'>
                                 <Form.Check type="checkbox" label=" Show More Tests Option" checked={more_test_check} onChange={(e)=> setMoreTestCheck(e.target.checked)} disabled={viewMode}/>
                                 
                                <Form.Control className='mt-3' as="textarea" rows={1} value={more_test_text} onChange={(e)=> setMoreTestText(e.target.value)} disabled={viewMode}/>
                                 
                             </Form.Group>
                            ) : ''
                            }
                        </Form>
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleSubmit}>
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>

            {/* </Modal> */}
            {/* </div> */}

        </>
    )
}

export default Categories